<!-- Settings -->
<template>
    <div class="overflow-auto">
        <div class="m-bot-32">
            <a @click="createModule"><b-button variant="success">Add New Module</b-button></a>
        </div>

        <table search-options="" id="module-table" role="table" aria-busy="false" aria-colcount="14" class="table-color table b-table table-hover table-sm">
            <thead role="rowgroup" class="">
                <tr role="row" class="">
                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Module ID</div></th>
                    <th role="columnheader" scope="col" aria-colindex="2" class=""><div>Module Name</div></th>
                </tr>
            </thead>
            <tbody role="rowgroup">
                <tr role="row" aria-rowindex="1" class="" v-for="moduledetail in list" v-bind:key="moduledetail.id">
                    <td aria-colindex="1" role="cell" class="">{{moduledetail.id}}</td> 
                    <td aria-colindex="2" role="cell" class="">{{moduledetail.controller_name}}</td>  
                    <td class="no-padding">
                        <b-dropdown id="dropdown-right" right text="Actions" class="m-md-2">
                          <b-dropdown-item @click="handleActions(1, moduledetail)"><i class="fa fa-pencil-alt"></i> Edit</b-dropdown-item>
                          <b-dropdown-item @click="handleActions(2, moduledetail)"><i class="fa fa-trash"></i> Delete</b-dropdown-item>
                        </b-dropdown>
                    </td>        
                </tr>
            </tbody>
        </table>
<v-dialog/>
<modals-container/>
     </div>
</template>


<style>
.table-color{background-color:#f7f7f7;}
.table-color td, .table-color th{padding:15px;}
.no-padding {padding:0px !important;}
    th{font-size:14px !important;}
    .m-bot-32{margin-bottom:32px;}
    .index-image img{width:100px;}
    .cursor a{cursor: pointer;}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllModules, deleteModule} from "@/api/access";
  export default {
    name: "list-website",
    data() {
      return { 
        list: [],
         loading: false,
         sort: null,
         pagination: {},
         q: null,
         image:''
      }
    },
    computed: {
     
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Settings & Permission", route: "/settings" },
        { title: "Modules" }
      ]);
    },
    created(){
      this.fetchData();
    },
    methods:{
      fetchData(p) {
      this.loading = true
      let params = { page: p }
      if(this.q){
        params.q = this.q
      }
      if(this.sort){
        params.sort = this.sort;
      }
      getAllModules(params).then(response => {
        this.list = response.data.data.data
        this.loading = false
      })
    },
    createModule()
    {
      this.$router.push({path:'/module/create/'})
    },
    handleActions(evt, row)
    {
        if (evt === 1){
          this.$router.push({path:'/module/edit/'+row.id})
        }
        else if (evt === 2){
          this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
            if(value){
              this.loading = true;
              deleteModule(row.id).then(response => {
                if(response.data.status){
                    this.fetchData(1)
                    this.$showResponse('success', response.data.message);
                }
                this.loading = false;
              })
            }else{
              return false;
            }
          }).catch(err => {
            console.log('Error',err)
          })
        }
    },
    }
  }
</script>